/* eslint-plugin-disable react */
/*eslint-disable */

/**
 * Variable(s)
 */
import { apiDomain, tokenKey } from "./__variables";

/**
 * Core(s)
 */
import Model, { ModelError, Paginate, TModelError, TPaginate, TPayload } from "./model";

/**
 * Model(s)
 */
import { Product } from "./product.model";
import User, { IUser } from "./user.model";
import ProductVariant from "./productVariant.model";
import Media from "./modelMedia.model";
import { EOpenTypes } from "./userPoint.model";

export interface IProductReviewStatistical {
   total: number;
   point5: number;
   point4: number;
   point3: number;
   point2: number;
   point1: number;
   hasContent: number;
   hasImage: number;
}

export enum EContentable {
   yes = "yes",
   no = "no",
}

export enum EReviewStatus {
   initializing = "initializing",
   pending = "pending",
   enable = "enable",
   disable = "disable",
}

export enum EUsageStatus {
   pending = "pending",
   applied = "applied",
}
export interface IReactionCount {
   _id: string;
   like: number;
}
export interface IReviewReward {
   _id: string;
   info: {
      expiredAt: string;
      message: string;
      openId: string;
      openType: "reviewProduct";
      value: number;
   };
}

export interface IProductReview extends Model {
   ["info"]: {
      ["point"]: number;
      ["content"]: string | null;
      ["reviewerEmail"]?: string;
      ["reviewerName"]?: string;
      ["reviewerPhone"]?: string;
   };
   ["options"]: {
      ["status"]: EReviewStatus;
      ["usageStatus"]: EUsageStatus;
   };
   ["bonusPoint"]: number;
   ["variantId"]: string;
   ["orderId"]: string;
   ["userId"]: string;
   ["publishedAt"]: string;
   ["expiredAt"]: string;
   ["createdAt"]: string;
   ["updatedAt"]: string;

   ["__user"]?: IUser;
   ["__reactionCount"]?: IReactionCount;
   ["__productVariant"]?: ProductVariant;
   ["__productReviewImages"]?: Media[];
   ["__currentUserReactionsMap"]: {
      like?: IReactionCount;
   };
   ["__reviewReward"]?: IReviewReward;
}

export class ProductReview extends Model<IProductReview> implements IProductReview {
   public ["info"]!: {
      ["point"]: number;
      ["content"]: string | null;
      ["reviewerEmail"]?: string;
      ["reviewerName"]?: string;
      ["reviewerPhone"]?: string;
   };
   public ["options"]!: {
      ["status"]: EReviewStatus;
      ["usageStatus"]: EUsageStatus;
   };
   public ["bonusPoint"]!: number;
   public ["variantId"]!: string;
   public ["orderId"]!: string;
   public ["userId"]!: string;
   public ["publishedAt"]!: string;
   public ["expiredAt"]!: string;
   public ["createdAt"]!: string;
   public ["updatedAt"]!: string;

   public ["__user"]?: User;
   public ["__reactionCount"]?: IReactionCount;
   public ["__productVariant"]?: ProductVariant;
   public ["__productReviewImages"]?: Media[];
   public ["__currentUserReactionsMap"]: {
      like?: IReactionCount;
   };
   public ["__reviewReward"]!: IReviewReward;

   constructor(data?: IProductReview) {
      super();

      Object.assign(this, data);

      if (this.__user && !(this.__user instanceof User)) {
         this.__user = new User(this.__user);
      }
      if (this.__productVariant && !(this.__productVariant instanceof ProductVariant)) {
         this.__productVariant = new ProductVariant(this.__productVariant);
      }
      if (this.__productReviewImages && !(this.__productReviewImages instanceof Media)) {
         this.__productReviewImages = this.__productReviewImages.map((item) => new Media(item));
      }
   }

   /**
    *
    * @param page
    * @param limit
    * @param sort
    * @param sortBy
    * @returns
    */
   public static async productReviewPaginate({
      id,
      page,
      limit,
      sort,
      sortBy,
      point,
      token,
      hasContent,
      hasImage,
   }: {
      id: string;
      token?: string;
      point?: string;
      page?: number;
      limit?: number;
      sort?: "desc" | "asc";
      sortBy?: "publishedAt" | "info.point";
      hasContent?: boolean;
      hasImage?: boolean;
   }) {
      const clientToken = token ? token : typeof window !== "undefined" ? localStorage.getItem(tokenKey) : undefined;
      try {
         const response = await fetch(`${apiDomain}/www/product/review/paginate`, {
            ["method"]: "GET",
            ["headers"]: {
               ["Origin"]: "https://spacet.vn",
               ["X-Requested-With"]: "XMLHttpRequest",
               ...(!clientToken ? {} : { Authorization: `Bearer ${clientToken}` }),
               ["page"]: !page ? String(1) : String(page),
               ["limit"]: !limit ? String(20) : String(limit),
               ["sort"]: sort ?? "desc",
               ["sort-by"]: sortBy ?? "publishedAt",
               ["id"]: id,
               ...(!point ? undefined : { ["point"]: String(point) }),
               ...(!hasContent ? undefined : { ["has-content"]: String(hasContent) }),
               ...(!hasImage ? undefined : { ["has-image"]: String(hasImage) }),
            },
         });

         if (!response.ok) {
            const errorPayload = await response.json();
            throw new ModelError(errorPayload as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            paginator: TPaginate<IProductReview>;
            statistics: IProductReviewStatistical;
         }>;

         return {
            paginate: new Paginate(payload?.data?.paginator, ProductReview),
            productReviewStatistical: payload?.data?.statistics,
         };
      } catch (error) {
         return new ModelError({
            ["httpCode"]: 500,
            ["message"]: error.message as string,
            ["errors"]: {
               ["process"]: [
                  {
                     ["code"]: "process.error.5000",
                     ["message"]: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }

   public static async like({ id }: { id: string }) {
      try {
         const res = await Model.fetchData({
            method: "POST",
            endPoint: "product/review/like",
            headers: {
               id,
            },
         });
         if (res instanceof ModelError) {
            return res;
         }

         const payload = (await res.json()) as TPayload<{
            productReaction: any;
         }>;
         return payload.data.productReaction;
      } catch (error) {
         return new ModelError(error);
      }
   }
   /*
    */
   public static async unLike({ id }: { id: string }) {
      try {
         const res = await Model.fetchData({
            method: "DELETE",
            endPoint: "product/review/like",
            headers: {
               id,
            },
         });
         if (res instanceof ModelError) {
            return res;
         }

         const payload = (await res.json()) as TPayload<{
            productReaction: any;
         }>;
         return payload.data.productReaction;
      } catch (error) {
         return new ModelError(error);
      }
   }
}

export default ProductReview;
