/* eslint-disable max-len */
import { WithRouterProps } from "next/dist/client/with-router";
import Link from "next/link";
import { withRouter } from "next/router";
import React from "react";
import store from "state";
import { toggleModalAuth } from "state/action/auth";
import { EAuthType, EStep } from "./auth/const";
interface IProps {
   handleClose: () => void;
   show?: boolean;
   notification?: number | null;
   link?: string;
   timeout?: number;
}
interface IState {}
class Bookmark extends React.Component<WithRouterProps & IProps, IState> {
   constructor(props: WithRouterProps & IProps) {
      super(props);
      this.state = {};
   }

   componentDidUpdate(
      _prevProps: Readonly<WithRouterProps & IProps>,
      _prevState: Readonly<IState>,
      _snapshot?: any
   ): void {
      if (this.props.show) {
         setTimeout(() => {
            this.props.handleClose();
         }, this.props.timeout || 4000);
      }
   }

   handleLogin = () => {
      store.dispatch(
         toggleModalAuth({
            visible: true,
            step: EStep.signup,
            type: EAuthType.signup,
         })
      );
   };

   render() {
      if (!this.props.notification) return;
      return (
         <div className={`modal-bookmark ${this.props.show ? " d-block" : " d-none"}`} onClick={this.props.handleClose}>
            <div className="modal-main">
               {(() => {
                  switch (this.props.notification) {
                     case 200:
                        return (
                           <div className="d-flex justify-content-between align-items-center gx-0">
                              <p className="success">
                                 <span className="d-flex align-items-center">
                                    <svg
                                       xmlns="http://www.w3.org/2000/svg"
                                       width="8"
                                       height="14"
                                       viewBox="0 0 8 14"
                                       fill="none"
                                    >
                                       <path
                                          d="M8 13V4C8 3.44772 7.55228 3 7 3H1C0.447715 3 0 3.44772 0 4V13L4.00011 9.38291L8 13Z"
                                          fill="white"
                                       />
                                    </svg>
                                    Lưu trữ thành công
                                 </span>
                              </p>
                              <div className="d-flex align-items-center">
                                 <Link
                                    href={this.props.link ?? "/tai-khoan?typePost=luu-tru"}
                                    className="edit d-flex align-items-center"
                                 >
                                    <span style={{ lineHeight: "1.2" }}>Xem </span>
                                    <svg
                                       xmlns="http://www.w3.org/2000/svg"
                                       width="6"
                                       height="6"
                                       viewBox="0 0 6 6"
                                       fill="none"
                                    >
                                       <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M1.36529 5.8167C1.24584 5.93858 1.09066 6 0.924853 6C0.820274 6 0.713183 5.97583 0.614184 5.91849C0.520083 5.86519 0.443599 5.78943 0.389491 5.69615C0.326898 5.59443 0.30137 5.48327 0.30137 5.37592C0.30137 5.21131 0.363154 5.06371 0.46983 4.94706L0.477437 4.93874L2.48073 2.9998L0.480165 1.06063L0.475189 1.05542C0.361367 0.936142 0.301369 0.784752 0.301369 0.620818C0.301369 0.510636 0.32917 0.402415 0.387286 0.304425C0.442054 0.208495 0.521352 0.13024 0.621085 0.0775765C0.718149 0.0230592 0.822699 2.77768e-07 0.924853 2.68838e-07C1.087 2.54663e-07 1.24181 0.0577071 1.36296 0.177777L3.80385 2.5463C3.86258 2.60146 3.91501 2.67007 3.95071 2.75356C3.98554 2.83501 4 2.91891 4 3C4 3.08216 3.98436 3.16448 3.95071 3.24318C3.91501 3.32667 3.86258 3.39527 3.80386 3.45043L1.36529 5.8167Z"
                                          fill="white"
                                       />
                                    </svg>
                                 </Link>
                              </div>
                           </div>
                        );
                     case 401:
                        return (
                           <div className="d-flex justify-content-between align-items-center gx-0">
                              <p className="success">
                                 <a onClick={this.handleLogin} className="hightlight">
                                    Đăng nhập
                                 </a>
                                 <span> để tiếp tục</span>
                              </p>
                              <div className="d-flex align-items-center" style={{ cursor: "pointer" }}>
                                 <a className="edit" onClick={this.handleLogin}>
                                    OK
                                 </a>
                                 {/* <Link to="/?login=true" className="edit">OK</Link> */}
                                 <img
                                    src="/assets/img/icon/close-bm.svg"
                                    alt="Space T"
                                    onClick={this.props.handleClose}
                                    className="icon-close"
                                 />
                              </div>
                           </div>
                        );
                     case 999:
                        return (
                           <div className="d-flex justify-content-between align-items-center gx-0">
                              <p className="success">
                                 {/* <span className="hightlight">Đã xóa lưu trữ</span> */}
                                 <span>Đã xóa lưu trữ</span>
                              </p>
                              <img
                                 src="/assets/img/icon/close-bm.svg"
                                 alt="Space T"
                                 onClick={this.props.handleClose}
                                 className="icon-close"
                              />
                           </div>
                        );
                     default:
                        return (
                           <div className="d-flex justify-content-between align-items-center gx-0">
                              <p className="success">
                                 <span className="hightlight">Lỗi server</span>
                                 <span> vui lòng thử lại</span>
                              </p>
                              <img
                                 src="/assets/img/icon/close-bm.svg"
                                 alt="Space T"
                                 onClick={this.props.handleClose}
                                 className="icon-close"
                              />
                           </div>
                        );
                  }
               })()}
            </div>
         </div>
      );
   }
}

export default withRouter(Bookmark);
