import ActualWork from "./actualWork.model";
import Media from "./modelMedia.model";

export interface IActualWorkMedia extends Media {
   ["actualWorkId"]?: string | null;

   ["__actualWork"]?: ActualWork | null;
}

export class ActualWorkMedia extends Media<IActualWorkMedia> implements IActualWorkMedia {
   public ["actualWorkId"]!: string | null;

   public ["__actualWork"]!: ActualWork | null;

   constructor(data?: IActualWorkMedia) {
      super(data);
      Object.assign(this, data);
   }
}
