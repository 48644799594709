import { handleSSRResponseError } from "@Utils/index";
import ArticleCategoryMedia from "@Models/articleCategoryMedia.model";
import { Model, IModel, ModelError, TPayload, TPaginate, Paginate } from "./model";

export enum EStatus {
   enable = "enable",
   disable = "disable",
   delete = "delete",
}

export interface IArticleCategory extends IModel {
   ["info"]?: {
      ["name"]?: string | null;
      ["slug"]?: string | null;
      ["description"]?: string | null;
   };
   ["options"]?: {
      ["status"]?: EStatus | null;
   };
   ["__media"]: ArticleCategoryMedia | null;
}

export class ArticleCategory extends Model<IArticleCategory> implements IArticleCategory {
   public ["info"]!: {
      ["name"]?: string | null;
      ["slug"]?: string | null;
      ["description"]?: string | null;
   };
   public ["options"]!: {
      ["status"]?: EStatus | null;
   };
   public ["__media"]!: ArticleCategoryMedia | null;

   constructor(data?: IArticleCategory) {
      super(data);
      Object.assign(this, data);
      if (this.__media) {
         this.__media = new ArticleCategoryMedia(this.__media);
      }
   }

   /**
    *
    * @param articleCategorySlug
    * @returns
    */
   public static async getArticleCategoryBySlug(articleCategorySlug: string) {
      const response = await Model.fetchGet(
         null,
         {
            slug: articleCategorySlug,
         },
         "article-category"
      );
      if (response instanceof ModelError) {
         return response;
      }

      const payload = (await response.json()) as TPayload<{
         ["articleCategory"]: IArticleCategory;
      }>;

      return {
         ["articleCategory"]: new ArticleCategory(payload.data.articleCategory),
      };
   }

   /**
    * code improve ngay 23 thang 01 nam 2025
    */
   public static async paginateArticleCategory({
      keywords,
      page,
      limit,
      sort,
      sortBy,
      categories,
      categorySlugs,
   }: {
      keywords?: string;
      categories?: string;
      categorySlugs?: string;
      page?: number;
      limit?: number;
      sort?: "desc" | "asc";
      sortBy?: "createdAt" | "info.title" | "info.position";
   }) {
      try {
         const response = await Model.fetchData({
            method: "GET",
            endPoint: "article-category/paginate",
            headers: {
               page: String(page || 1),
               limit: String(limit || 20),
               sort: sort ?? "desc",
               "sort-by": sortBy ?? "createdAt",
               ...(keywords && { keywords: encodeURI(keywords) }),
               ...(categories && { categories: encodeURI(categories) }),
               ...(categorySlugs && {
                  "category-slugs": encodeURI(categorySlugs),
               }),
            },
         });

         // Nếu response là lỗi ModelError, trả về ngay
         if (response instanceof ModelError) {
            return response;
         }

         // Kiểm tra lỗi HTTP thông qua handleSSRResponseError
         await handleSSRResponseError(response);

         // Đọc response dưới dạng text trước khi parse JSON
         const payloadText = await response.text();

         if (!payloadText) {
            throw new ModelError({ message: "Response payload is empty.", httpCode: 500 });
         }

         let jsonPayload: TPayload<{ paginator: TPaginate<ArticleCategory> }>;

         try {
            jsonPayload = JSON.parse(payloadText);
         } catch {
            throw new ModelError({ message: "Invalid JSON response format.", httpCode: 500 });
         }

         // Kiểm tra dữ liệu trả về có hợp lệ không
         if (!jsonPayload?.data?.paginator) {
            throw new ModelError({ message: "Dữ liệu phản hồi không hợp lệ hoặc thiếu paginator.", httpCode: 500 });
         }

         // Trả về dữ liệu đã kiểm tra
         return new Paginate(jsonPayload.data.paginator, ArticleCategory);
      } catch (error) {}
   }
}

export default ArticleCategory;
