/**
 * Variable(s)
 */
import { resizeImage } from "utils/resizeImage";
import { apiDomain, tokenKey } from "./__variables";

/**
 * Core(s)
 */
import Model, { Paginate, ModelError, TModelError, TPayload, TPaginate } from "./model";

import User from "./user.model";

export enum EUserMediaStatus {
   enable = "enable",
   disable = "disable",
}

export enum EUserMediaStorageProvider {
   Amazon = "amazon",
   Microsoft = "microsoft",
   Others = "others",
}

export enum ESolutionResizeImage {
   xs = "150",
   sm = "320",
   md = "640",
   lg = "1440",
}

export interface IUserMedia extends Model {
   ["info"]?: {
      ["container"]: string | null;
      ["domain"]: string | null;
      ["originalName"]: string | null;
      ["protocol"]: string | null;
      ["size"]: number | null;
      ["extension"]: string | null;
      ["fileType"]: string | null;
   };
   ["options"]: {
      ["status"]: EUserMediaStatus;
      ["storageProvider"]: EUserMediaStorageProvider;
      ["isMigrated"]?: boolean;
   };
   ["userId"]?: string | null;

   ["__user"]?: User | null;
   ["$url"]?: any | null;
}
export class UserMedia extends Model<IUserMedia> implements IUserMedia {
   public ["info"]!: {
      ["container"]: string | null;
      ["domain"]: string | null;
      ["originalName"]: string | null;
      ["protocol"]: string | null;
      ["size"]: number | null;
      ["extension"]: string | null;
      ["fileType"]: string | null;
      ["isMigrated"]?: boolean;
   };
   ["options"]!: {
      ["status"]: EUserMediaStatus;
      ["storageProvider"]: EUserMediaStorageProvider;
      ["isMigrated"]?: boolean;
   };
   ["userId"]!: string | null;

   ["__user"]!: User | null;
   public ["$url"]?: any | null;
   constructor(data?: IUserMedia) {
      super(data);

      Object.assign(this, data);

      if (this.__user && !(this.__user instanceof User)) {
         this.__user = new User(this.__user);
      }

      const { $url } = resizeImage(this._id, this.info, this.info?.isMigrated ?? this.options?.isMigrated);
      this.$url = $url;

      // const filePath = this.options?.isMigrated ? `${this.info?.originalName}` : `${this._id}${this.info?.extension}`;
      // if (filePath && this.info?.protocol && this.info?.domain && this.info?.container) {
      //    this.$url = `${this.info?.protocol}://${this.info?.domain}/${this.info?.container}/${filePath}`;
      // }
   }
   public static async createImage(file: File) {
      try {
         const token = localStorage.getItem(tokenKey);
         if (!token) {
            return new ModelError({
               ["httpCode"]: 401,
               ["message"]: "You don't have permission to access this API.",
               ["errors"]: {
                  ["process"]: [
                     {
                        ["code"]: "process.error.401",
                        ["message"]: "Upload ảnh không thành công.",
                     },
                  ],
               },
            });
         }
         const formData = new FormData();
         formData.append("file", file, file.name);
         const response = await fetch(`${apiDomain}/www/user/media`, {
            ["method"]: "POST",
            ["headers"]: {
               ["X-Requested-With"]: "XMLHttpRequest",
               ["Authorization"]: `Bearer ${token}`,
            },
            ["body"]: formData,
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }
         const payload = (await response.json()) as TPayload<{
            ["newUserMedia"]: IUserMedia;
         }>;
         return new UserMedia(payload.data.newUserMedia);
      } catch (error) {}
   }
}

export default UserMedia;
