import { useEffect, useState } from "react";
import classNames from "classnames";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Image from "next/image";
import __variables from "models/__variables";
import { currency } from "../../../utils";
import ProductTaggingImage, {
   EProductTaggingImageProductTypes,
   EProductTaggingImageStatus,
} from "../../../models/productTaggingImage.model";

export interface ITaggingIsShowed {
   tagging?: ProductTaggingImage;
   anchor: null | HTMLElement;
   anchorOrigin?: {
      vertical: EAnchorVertical;
      horizontal: EAnchorHorizontal;
   };
   transformOrigin: {
      vertical: EAnchorVertical;
      horizontal: EAnchorHorizontal;
   };
}

interface IProps {
   router?: any;
   index: number;
   showTagging?: boolean;
   tagging: ProductTaggingImage;
   onDeleteProductTagging?: (product: ProductTaggingImage) => void;
   setToggleDialogProductNotAccept?: React.Dispatch<React.SetStateAction<boolean>>;
   rel?: string;
   toggleProgressbar: () => void;
}

enum EAnchorVertical {
   bottom = "bottom",
   top = "top",
   center = "center",
}
enum EAnchorHorizontal {
   left = "left",
   right = "right",
   center = "center",
}

export default function TaggingItem({
   router,
   index,
   showTagging,
   tagging,
   toggleProgressbar,
   onDeleteProductTagging,
   setToggleDialogProductNotAccept,
   rel,
   ...props
}: IProps) {
   const [taggingIsShowed, setTaggingIsShowed] = useState<boolean | undefined>(false);

   useEffect(() => {
      setTaggingIsShowed(showTagging);
   }, [showTagging]);

   const unverified = tagging?.options.status === EProductTaggingImageStatus.unverified;
   const showAtRight = tagging.position.leftPercent < 50;

   const price =
      tagging?.__product?.info?.priceDisplaySmallest ||
      tagging?.__product?.__variants?.[0]?.info?.priceAfterDiscount ||
      tagging?.__product?.__variants?.[0]?.info?.price ||
      0;
   return (
      <div
         className={classNames(["tagging-point"])}
         style={{
            top: `calc(${tagging.position.topPercent}% - 7px)`,
            left: `calc(${tagging.position.leftPercent}% - 7px)`,
            // zIndex: taggingIsShowed.tagging?._id === tagging._id ? 100 : 9,
            // show hide animation
            // visibility: showTagging ? "unset" : "hidden",
            // opacity: showTagging ? 1 : 0
         }}
         onClick={(e) => {
            e.stopPropagation();
            setTaggingIsShowed((prev) => !prev);
         }}
         {...props}
      >
         <a
            className={classNames(["popover", showAtRight ? "right" : "left"])}
            style={{
               top: "50%",
               left: showAtRight ? "26px" : undefined,
               right: showAtRight ? undefined : "26px",
               transform: "translateY(-50%",
               visibility: taggingIsShowed ? "unset" : "hidden",
               opacity: taggingIsShowed ? 1 : 0,
            }}
            onClick={(e) => {
               e.preventDefault();
               e.stopPropagation();
               if (!tagging.urlTagging) {
                  setToggleDialogProductNotAccept?.(true);
               } else {
                  if (tagging.product.productType === EProductTaggingImageProductTypes.internal) {
                     router.push(tagging.urlTagging);
                     toggleProgressbar();
                  } else {
                     window.open(tagging.urlTagging, "_blank");
                  }
               }
            }}
            {...(rel ? { rel } : undefined)}
         >
            <div className={classNames("popover-tagging", "d-flex  align-items-center justify-content-between")}>
               <div className="d-flex align-items-center gap-2 w-100">
                  <div className="position-relative">
                     <div className={classNames(["thumbnail"])}>
                        {tagging.$url64ResizeMax || tagging.$url ? (
                           <img src={tagging.$url64ResizeMax || tagging.$url} alt="" />
                        ) : undefined}
                     </div>
                     {tagging?.options.similar ? <div className={"tag-similar-popover"}>Gợi ý</div> : undefined}
                  </div>
                  {tagging?.product ? (
                     <div className={classNames("content")}>
                        {tagging.product.productType === EProductTaggingImageProductTypes.internal &&
                        tagging?.__product ? (
                           <>
                              <span className={classNames(["truncate-line-2", "name"])}>
                                 {tagging?.__product?.info.title}
                              </span>
                              {price > 0 ? (
                                 <span className={classNames(["truncate-line-1", "price"])}>
                                    {currency(price)}
                                    <span className="underline">đ</span>
                                 </span>
                              ) : (
                                 <span className={classNames("price", "price-contact")}>Liên hệ</span>
                              )}
                           </>
                        ) : (
                           <>
                              <span className={classNames(["truncate-line-1", "name"])}>
                                 {tagging?.product.productName}
                              </span>
                              <span className={classNames(["truncate-line-1", "price"])}>
                                 {currency(tagging?.product.productPrice ?? 0)}
                                 <span className="underline">đ</span>
                              </span>
                           </>
                        )}
                        {unverified ? (
                           <div className="d-flex justify-content-between align-items-center">
                              <div className={"unverify"}>
                                 <Image
                                    width={11}
                                    height={11}
                                    src="/assets/img/icon/no-protection.svg"
                                    alt="Space T"
                                    className={"icon-protection"}
                                 />
                                 <span>Chờ duyệt</span>
                              </div>
                              {onDeleteProductTagging ? (
                                 <a
                                    onClick={(e) => {
                                       e.stopPropagation();
                                       onDeleteProductTagging(tagging);
                                    }}
                                    className={"remove-tagging"}
                                 >
                                    {/* <DeleteIcon width="13px" height="14px" fill="#fff" /> */}
                                    <svg
                                       width="20"
                                       height="20"
                                       viewBox="0 0 20 20"
                                       fill="none"
                                       xmlns="http://www.w3.org/2000/svg"
                                    >
                                       <rect x="0.75" y="0.75" width="18.5" height="18.5" rx="9.25" fill="white" />
                                       <rect
                                          x="0.75"
                                          y="0.75"
                                          width="18.5"
                                          height="18.5"
                                          rx="9.25"
                                          stroke="white"
                                          strokeWidth="0.5"
                                       />
                                       <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M7.03033 5.96967C6.73744 5.67678 6.26256 5.67678 5.96967 5.96967C5.67678 6.26256 5.67678 6.73744 5.96967 7.03033L8.93937 10L5.96967 12.9697C5.67678 13.2626 5.67678 13.7375 5.96967 14.0304C6.26256 14.3233 6.73744 14.3233 7.03033 14.0304L10 11.0607L12.9693 14.03C13.2622 14.3229 13.7371 14.3229 14.03 14.03C14.3229 13.7371 14.3229 13.2622 14.03 12.9693L11.0607 10L14.03 7.03072C14.3229 6.73783 14.3229 6.26295 14.03 5.97006C13.7371 5.67717 13.2622 5.67717 12.9693 5.97006L10 8.93937L7.03033 5.96967Z"
                                          fill="#252A2B"
                                       />
                                    </svg>
                                 </a>
                              ) : undefined}
                           </div>
                        ) : undefined}
                     </div>
                  ) : (
                     <span className={"product-not-exist"}>Product not exist!</span>
                  )}
                  {onDeleteProductTagging ? (
                     <a
                        onClick={(e) => {
                           e.stopPropagation();
                           onDeleteProductTagging(tagging);
                        }}
                        className={"remove-tagging"}
                     >
                        {/* <DeleteIcon width="13px" height="14px" fill="#fff" /> */}
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <rect x="0.75" y="0.75" width="18.5" height="18.5" rx="9.25" fill="white" />
                           <rect
                              x="0.75"
                              y="0.75"
                              width="18.5"
                              height="18.5"
                              rx="9.25"
                              stroke="white"
                              strokeWidth="0.5"
                           />
                           <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M7.03033 5.96967C6.73744 5.67678 6.26256 5.67678 5.96967 5.96967C5.67678 6.26256 5.67678 6.73744 5.96967 7.03033L8.93937 10L5.96967 12.9697C5.67678 13.2626 5.67678 13.7375 5.96967 14.0304C6.26256 14.3233 6.73744 14.3233 7.03033 14.0304L10 11.0607L12.9693 14.03C13.2622 14.3229 13.7371 14.3229 14.03 14.03C14.3229 13.7371 14.3229 13.2622 14.03 12.9693L11.0607 10L14.03 7.03072C14.3229 6.73783 14.3229 6.26295 14.03 5.97006C13.7371 5.67717 13.2622 5.67717 12.9693 5.97006L10 8.93937L7.03033 5.96967Z"
                              fill="#252A2B"
                           />
                        </svg>
                     </a>
                  ) : (
                     <ChevronRightIcon className={"icon-arrow"} style={{ fill: "rgb(156 156 156)" }} />
                  )}
               </div>
            </div>
         </a>
      </div>
   );
}
