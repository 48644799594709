import { fetchWithTimeout, handleSSRResponseError } from "@Utils/index";
import Model, { ModelError, TModelError, TPayload, Paginate, TPaginate, ESortBy } from "./model";
import Article from "./article.model";
import Media from "./modelMedia.model";
import Community from "./community.model";
import ActualWork, { EActualWorkTypes } from "./actualWork.model";
import { apiDomain, tokenKey } from "./__variables";
import UserBookmark, { EUserBookmarkTypes, IUserBookmark } from "./userBookmark.model";
import Keyword from "./keyword.model";
import { IInteriorStyle } from "./interiorStyle.model";

export enum EGeneralMediasBySpaceSortBy {
   createdAt = "createdAt",
   updatedAt = "updatedAt",
   viewCount = "info.viewCount",
}

export enum ETypeImageClick {
   "article" = "article",
   "design" = "design",
   "communityPost" = "communityPost",
   "actualWork" = "actualWork",
}

// export interface MetaImage {
//    altText: string;
//    description: string;
//    title: string;
// }

export interface IPostInfo {
   info: {
      title: string;
      description: string;
      slug: string;
      imageFeatured: string | null;
      publishedAt: string;
      bookmarkCount: number;
      viewCount: number;
   };
   options: {
      status: string;
      isMigrated: boolean;
      articleType?: EActualWorkTypes;
   };
   __styles: IInteriorStyle[];
   __partner: any;
   __location: any[];
   __category: any;
}

export interface IGeneralMediasBySpace extends Media {
   communityPostId?: string | null;
   __communityPost?: Community | null;

   actualWorkId?: string | null;
   _actualWork?: ActualWork | null;

   articleId?: string | null;
   __articlePost?: Article | null;
   id?: string | null;
   __post?: IPostInfo | null;
   __keywords?: Keyword[];
   url?: string;

   __currentUserBookmark?: UserBookmark;

   space?: string[];
}
export class GeneralMediasBySpace extends Media<IGeneralMediasBySpace> implements IGeneralMediasBySpace {
   public "communityPostId"?: string | null;
   public "__communityPost"?: Community | null;

   public "actualWorkId"?: string | null;
   public "__actualWork"!: ActualWork | null;

   public "articleId"?: string | null;
   public "__article"?: Article | null;

   public "url"?: string;

   public "id"?: string | null;
   public "__post"?: IPostInfo | null;
   public "__keywords"?: Keyword[];

   public "__currentUserBookmark"?: UserBookmark;

   public "space"?: string[];

   constructor(data?: IGeneralMediasBySpace) {
      super(data);
      Object.assign(this, data);

      // this.url = `${this.__post?.info?.slug}~${this._id}`;
   }

   get typeMedia(): string {
      if (this.actualWorkId) {
         return EUserBookmarkTypes.actualWorkMedia;
      }
      if (this.articleId) {
         return EUserBookmarkTypes.articleMedia;
      }
      if (this.communityPostId) {
         return EUserBookmarkTypes.communityPostMedia;
      }
      return "";
   }

   /**
    *
    * @param keywordIds
    * @param keywordColorIds
    * @param keywordInteriorStyleIds
    * @param page
    * @param limit
    * @param sort
    * @param sortBy
    * @returns
    */
   public static async paginate({
      keywords,
      keywordIds,
      keywordColorIds,
      keywordInteriorStyleIds,
      page,
      limit,
      sort = "desc",
      sortBy = EGeneralMediasBySpaceSortBy.viewCount,
      keywordSlug,
      keywordColorSlugs,
      keywordInteriorStyleSlugs,
      url,
      cookiesToken,
   }: {
      keywords?: string;
      keywordSlug?: string[] | undefined;
      keywordColorSlugs?: string[] | undefined;
      keywordInteriorStyleSlugs?: string[] | undefined;
      keywordIds?: string[] | undefined;
      keywordColorIds?: string[] | undefined;
      keywordInteriorStyleIds?: string[] | undefined;
      page?: number;
      limit?: number;
      sort?: "desc" | "asc";
      sortBy?: EGeneralMediasBySpaceSortBy;
      url?: string;
      cookiesToken?: string;
   }) {
      try {
         const token = typeof window !== "undefined" && localStorage.getItem(tokenKey);
         const lastToken = cookiesToken || token;
         const response = await fetch(`${apiDomain}/www/general/medias/by-space-distinct`, {
            method: "GET",
            cache: "default",
            headers: {
               ["Origin"]: "https://spacet.vn",
               "X-Requested-With": "XMLHttpRequest",
               page: !page ? String(1) : String(page),
               limit: !limit ? String(20) : String(limit),
               ...(!sort ? undefined : { sort }),
               ...(!sortBy ? undefined : { "sort-by": sortBy }),
               ...(!lastToken ? undefined : { Authorization: `Bearer ${lastToken}` }),
               ...(!keywords ? undefined : { keywords: encodeURI(keywords) }),
               ...(!keywordSlug ? undefined : { "keyword-slugs": encodeURI(keywordSlug.toString()) }),
               ...(!keywordColorSlugs
                  ? undefined
                  : {
                       "keyword-color-slugs": encodeURI(keywordColorSlugs.toString()),
                    }),
               ...(!keywordInteriorStyleSlugs
                  ? undefined
                  : {
                       "keyword-interior-style-slugs": encodeURI(keywordInteriorStyleSlugs.toString()),
                    }),
               ...(!keywordIds ? undefined : { "keyword-ids": encodeURI(keywordIds.toString()) }),
               ...(!keywordColorIds
                  ? undefined
                  : {
                       "keyword-color-ids": encodeURI(keywordColorIds.toString()),
                    }),
               ...(!keywordInteriorStyleIds
                  ? undefined
                  : {
                       "keyword-interior-style-ids": encodeURI(keywordInteriorStyleIds.toString()),
                    }),
               ...(!url ? undefined : { url: encodeURI(url.toString()) }),
            },
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            mediaPaginator: TPaginate<IGeneralMediasBySpace>;
            userBookmarks?: IUserBookmark[];
         }>;
         const paginate = new Paginate(payload.data.mediaPaginator, GeneralMediasBySpace);

         return { paginate, userBookmarks: payload.data?.userBookmarks ?? [] };
      } catch (error) {}
   }
   public static async bySpaceCount({
      keywordIds,
      keywordColorIds,
      keywordInteriorStyleIds,
      page,
      limit,
      sort = "desc",
      sortBy = EGeneralMediasBySpaceSortBy.viewCount,
      keywordSlug,
      keywordColorSlugs,
      keywordInteriorStyleSlugs,
      url,
      cookiesToken,
   }: {
      keywordSlug?: string[] | undefined;
      keywordColorSlugs?: string[] | undefined;
      keywordInteriorStyleSlugs?: string[] | undefined;
      keywordIds?: string[] | undefined;
      keywordColorIds?: string[] | undefined;
      keywordInteriorStyleIds?: string[] | undefined;
      page?: number;
      limit?: number;
      sort?: "desc" | "asc";
      sortBy?: EGeneralMediasBySpaceSortBy;
      url?: string;
      cookiesToken?: string;
   }) {
      try {
         const token = typeof window !== "undefined" && localStorage.getItem(tokenKey);
         const lastToken = cookiesToken || token;
         const response = await fetch(`${apiDomain}/www/general/medias/by-space-count`, {
            method: "GET",
            cache: "default",
            headers: {
               ["Origin"]: "https://spacet.vn",
               "X-Requested-With": "XMLHttpRequest",
               page: !page ? String(1) : String(page),
               limit: !limit ? String(20) : String(limit),
               ...(!sort ? undefined : { sort }),
               ...(!sortBy ? undefined : { "sort-by": sortBy }),
               ...(!lastToken ? undefined : { Authorization: `Bearer ${lastToken}` }),
               ...(!keywordSlug ? undefined : { "keyword-slugs": encodeURI(keywordSlug.toString()) }),
               ...(!keywordColorSlugs
                  ? undefined
                  : {
                       "keyword-color-slugs": encodeURI(keywordColorSlugs.toString()),
                    }),
               ...(!keywordInteriorStyleSlugs
                  ? undefined
                  : {
                       "keyword-interior-style-slugs": encodeURI(keywordInteriorStyleSlugs.toString()),
                    }),
               ...(!keywordIds ? undefined : { "keyword-ids": encodeURI(keywordIds.toString()) }),
               ...(!keywordColorIds
                  ? undefined
                  : {
                       "keyword-color-ids": encodeURI(keywordColorIds.toString()),
                    }),
               ...(!keywordInteriorStyleIds
                  ? undefined
                  : {
                       "keyword-interior-style-ids": encodeURI(keywordInteriorStyleIds.toString()),
                    }),
               ...(!url ? undefined : { url: encodeURI(url.toString()) }),
            },
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = await response.json();

         return payload.data;
      } catch (error) {}
   }

   /**
    * Code improve ngay 23 thang 01 nam 2025
    */
   public static async paginatev2({
      keywords,
      keywordIds,
      keywordColorIds,
      keywordInteriorStyleIds,
      page,
      limit,
      sort,
      sortBy = EGeneralMediasBySpaceSortBy.viewCount,
      keywordSlug,
      keywordColorSlugs,
      keywordInteriorStyleSlugs,
      cookiesToken,
      url,
   }: {
      keywords?: string;
      keywordSlug?: string[] | undefined;
      keywordColorSlugs?: string[] | undefined;
      keywordInteriorStyleSlugs?: string[] | undefined;
      keywordIds?: string[] | undefined;
      keywordColorIds?: string[] | undefined;
      keywordInteriorStyleIds?: string[] | undefined;
      page?: number;
      limit?: number;
      sort?: "desc" | "asc";
      sortBy?: EGeneralMediasBySpaceSortBy;
      cookiesToken?: string;
      url?: string;
   }) {
      try {
         const token = typeof window !== "undefined" && localStorage.getItem(tokenKey);
         const lastToken = cookiesToken || token;

         const response = await fetchWithTimeout(
            `${apiDomain}/www/general/medias/by-space-distinct/v2?offcontent=true`,
            {
               method: "GET",
               cache: "default",
               headers: {
                  ["Origin"]: "https://spacet.vn",
                  "X-Requested-With": "XMLHttpRequest",
                  page: !page ? String(1) : String(page),
                  limit: !limit ? String(20) : String(limit),
                  sort: sort ?? "desc",
                  ["sort-by"]: sortBy ?? ESortBy.createdAt,
                  ...(!lastToken ? undefined : { Authorization: `Bearer ${lastToken}` }),
                  ...(!keywords ? undefined : { keywords: encodeURI(keywords) }),
                  ...(!keywordSlug ? undefined : { "keyword-slugs": encodeURI(keywordSlug.toString()) }),
                  ...(!keywordColorSlugs
                     ? undefined
                     : {
                          "keyword-color-slugs": encodeURI(keywordColorSlugs.toString()),
                       }),
                  ...(!keywordInteriorStyleSlugs
                     ? undefined
                     : {
                          "keyword-interior-style-slugs": encodeURI(keywordInteriorStyleSlugs.toString()),
                       }),
                  ...(!keywordIds ? undefined : { "keyword-ids": encodeURI(keywordIds.toString()) }),
                  ...(!keywordColorIds
                     ? undefined
                     : {
                          "keyword-color-ids": encodeURI(keywordColorIds.toString()),
                       }),
                  ...(!keywordInteriorStyleIds
                     ? undefined
                     : {
                          "keyword-interior-style-ids": encodeURI(keywordInteriorStyleIds.toString()),
                       }),
                  ...(!url ? undefined : { url: encodeURI(url.toString()) }),
               },
            }
         );

         await handleSSRResponseError(response);

         const payloadText = await response.text();
         if (!payloadText) {
            throw new ModelError({ httpCode: 500, message: "Empty response from API." });
         }

         let payload: TPayload<{ mediaPaginator: TPaginate<IGeneralMediasBySpace> }>;

         try {
            payload = JSON.parse(payloadText) as TPayload<{ mediaPaginator: TPaginate<IGeneralMediasBySpace> }>;
         } catch {
            throw new ModelError({ httpCode: 500, message: "Invalid JSON response." });
         }

         if (!payload?.data?.mediaPaginator) {
            throw new ModelError({ httpCode: 500, message: "Missing media paginator data." });
         }

         return new Paginate(payload.data.mediaPaginator, GeneralMediasBySpace);
      } catch (error) {
         return new ModelError(error as TModelError);
      }
   }

   /**
    *
    * @param id
    * @param type
    * @returns
    */
   public static async getByTargetMedia(id: string, type: string) {
      try {
         const token = localStorage.getItem(tokenKey);
         const response = await fetch(`${apiDomain}/www/general/medias/by-target-media`, {
            method: "GET",
            cache: "default",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               type: type,
               id: id,
               ...(!token ? undefined : { Authorization: `Bearer ${token}` }),
            },
         });
         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            targetMedia: GeneralMediasBySpace;
            relatedMedias: IGeneralMediasBySpace[];
            userBookmarks: IUserBookmark[];
         }>;
         return {
            targetMedia: new GeneralMediasBySpace(payload.data.targetMedia),
            relatedMedias: payload.data.relatedMedias?.map((el) => new GeneralMediasBySpace(el)),
            userBookmarks: payload.data?.userBookmarks ?? []?.map((bookmark) => new UserBookmark(bookmark)),
         };
      } catch (error) {
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }

   public static async getDetailImageBySpace(id: string, cookiesToken?: string) {
      try {
         const token = typeof window !== "undefined" ? localStorage.getItem(tokenKey) : undefined;
         const lastToken = cookiesToken || token;
         const response = await fetch(`${apiDomain}/www/general/medias/by-target-media-id/v2`, {
            method: "GET",
            cache: "no-store",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               ["Origin"]: "https://spacet.vn",
               id: id,
               ...(!lastToken ? undefined : { Authorization: `Bearer ${lastToken}` }),
            },
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            targetMedia: GeneralMediasBySpace;
            relatedMedias: IGeneralMediasBySpace[];
         }>;
         return {
            targetMedia: new GeneralMediasBySpace(payload?.data?.targetMedia),
            relatedMedias: payload.data?.relatedMedias?.map((el) => new GeneralMediasBySpace(el)),
         };
      } catch (error) {}
   }

   public static async getNextAndPrevIdByIdImage({
      id,
      page,
      keywordRoomId,
      keywordInteriorStyleIds,
      keywordColorIds,
      keywordSlugs,
      keywordColorSlugs,
      keywordInteriorStyleSlugs,
   }: {
      id: string;
      page?: string;
      keywordSlugs?: string[] | undefined;
      keywordColorSlugs?: string[] | undefined;
      keywordInteriorStyleSlugs?: string[] | undefined;
      keywordRoomId?: string;
      keywordInteriorStyleIds?: string;
      keywordColorIds?: string;
   }) {
      try {
         const token = localStorage.getItem(tokenKey);
         const response = await fetch(`${apiDomain}/www/general/medias/by-target-media-id/near-medias`, {
            method: "GET",
            cache: "default",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               id: id,
               limit: String(20),
               ...(!token ? undefined : { Authorization: `Bearer ${token}` }),
               ...(!page ? undefined : { page: String(page) }),
               ...(!keywordRoomId ? undefined : { ["keyword-ids"]: String(keywordRoomId) }),
               ...(!keywordInteriorStyleIds
                  ? undefined
                  : {
                       ["keyword-interior-style-ids"]: String(keywordInteriorStyleIds),
                    }),
               ...(!keywordColorIds ? undefined : { ["keyword-color-ids"]: String(keywordColorIds) }),
               ...(!keywordSlugs ? undefined : { "keyword-slugs": encodeURI(keywordSlugs.toString()) }),
               ...(!keywordColorSlugs
                  ? undefined
                  : {
                       "keyword-color-slugs": encodeURI(keywordColorSlugs.toString()),
                    }),
               ...(!keywordInteriorStyleSlugs
                  ? undefined
                  : {
                       "keyword-interior-style-slugs": encodeURI(keywordInteriorStyleSlugs.toString()),
                    }),
            },
         });
         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = await response.json();

         return payload.data;
      } catch (error) {
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }
   public static async getNextAndPrevIdByIdImageFollowSameArticle(id: string) {
      try {
         const token = localStorage.getItem(tokenKey);
         const response = await fetch(`${apiDomain}/www/general/medias/by-target-media-id/same-post/near-medias`, {
            method: "GET",
            cache: "default",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               id: id,
               limit: String(24),
               ...(!token ? undefined : { Authorization: `Bearer ${token}` }),
            },
         });
         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = await response.json();

         return payload.data;
      } catch (error) {
         return new ModelError({
            httpCode: 500,

            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }
   public static async getAllItemsImageLibrary() {
      try {
         // const token = typeof window !== "undefined" && localStorage.getItem(tokenKey);
         const response = await fetch(`${apiDomain}/www/general/library-image-slugs`, {
            ["method"]: "GET",
            // ["cache"]: "no-store",
            ["headers"]: {
               ["Origin"]: "https://spacet.vn",
               ["X-Requested-With"]: "XMLHttpRequest",
            },
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = await response.json();
         return payload.data;
      } catch (error) {}
   }
   /**
    *
    * @param url
    * @param type
    * @returns
    */
   public static async getContentImageClick({
      url,
      type,
      cookiesToken,
   }: {
      url: string;
      type: ETypeImageClick;
      cookiesToken?: string;
   }) {
      try {
         const token = typeof window !== "undefined" && localStorage.getItem(tokenKey);
         const lastToken = cookiesToken || token;
         const response = await Model.fetchData({
            method: "GET",
            endPoint: "general/medias/content-image-clicked",
            headers: {
               ...(!lastToken ? undefined : { Authorization: `Bearer ${lastToken}` }),
               ...(!url ? undefined : { url }),
               ...(!type ? undefined : { type }),
            },
         });

         if (response instanceof ModelError) {
            return response;
         }

         const payload = (await (response as any).json()) as TPayload<{
            media: Media;
            clickAble: boolean;
         }>;

         return payload.data;
      } catch (error) {
         return new ModelError(error);
      }
   }

   public static async getSuggestionsByIdImagev2(id: string, page?: number, limit?: number, cookiesToken?: string) {
      try {
         const token = typeof window !== "undefined" && localStorage.getItem(tokenKey);
         const lastToken = cookiesToken || token;
         const response = await Model.fetchData({
            method: "GET",
            endPoint: "general/medias/by-target-media-id/suggestion/v2",
            headers: {
               ["Origin"]: "https://spacet.vn",
               id: id,
               ...(!page ? undefined : { page: String(page) }),
               ["limit"]: String(limit ?? 12),
               ...(!lastToken ? undefined : { Authorization: `Bearer ${lastToken}` }),
            },
         });

         if (response instanceof ModelError) {
            return response;
         }

         const payload = (await (response as any).json()) as TPayload<{
            paginator: TPaginate<IGeneralMediasBySpace>;
         }>;

         const paginate = new Paginate(payload?.data?.paginator, GeneralMediasBySpace);
         return paginate;
      } catch (error) {
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }
}

export default GeneralMediasBySpace;
