/**
 * Model(s)
 */
import { Model, ModelError, TModelError, TPayload } from "./model";
import ProductTaggingImage from "./productTaggingImage.model";
import { apiDomain, tokenKey } from "./__variables";
import Media, { IMedia } from "./modelMedia.model";

export enum ESocialFeedMediaSortBy {
   createdAt = "createdAt",
   updatedAt = "updatedAt",
}
export enum EFileType {
   apng = "image/apng",
   avif = "image/avif",
   gif = "image/gif",
   jpeg = "image/jpeg",
   svg = "image/svg+xml",
   png = "image/png",
   webp = "image/webp",
}
export enum ESocialFeedMediaStatus {
   enable = "enable",
   disable = "disable",
}

export enum ESocialFeedMediaStorageProvider {
   Amazon = "amazon",
   Microsoft = "microsoft",
   Others = "others",
}

export enum ESolutionResizeImage {
   xs = "150",
   sm = "320",
   md = "640",
   lg = "1440",
}
export interface IPresignedPost {
   ["url"]: string;
   ["fields"]: {
      [key: string]: string;
   };
}
export interface ISizeMedia {
   width: number | undefined;
   height: number | undefined;
}
export interface ISocialFeedMedia extends IMedia {
   ["__productTagging"]?: ProductTaggingImage[];
}

export default class SocialFeedMedia extends Media<ISocialFeedMedia> implements ISocialFeedMedia {
   public ["__productTagging"]?: ProductTaggingImage[];

   constructor(data?: ISocialFeedMedia) {
      super(data);
      Object.assign(this, data);
      if (this.__productTagging) {
         this.__productTagging = this.__productTagging.map((tagging) => new ProductTaggingImage(tagging));
      }
   }

   /**
    *
    * @param
    * @returns
    */

   private static async presignedPost(presignedPost: IPresignedPost, file: File, socialFeedMedia: ISocialFeedMedia) {
      const formData = new FormData();
      formData.append("Content-Type", file.type);
      formData.append(
         "key",
         `${socialFeedMedia.info?.container}/${socialFeedMedia._id}${socialFeedMedia?.info?.extension}`
      );
      formData.append("file", file);

      for (const key in presignedPost.fields) {
         formData.append(key, presignedPost.fields[key]);
      }
      const response = await fetch(`${presignedPost.url}`, {
         method: "POST",
         headers: {
            "X-Requested-With": "XMLHttpRequest",
         },
         body: formData,
      });
      if (!response.ok) {
         return new ModelError((await response.json()) as TModelError);
      }
      return response;
   }

   /**
    *
    * @param file
    * @param sizeMedia
    * @param SocialFeedId
    * @returns
    */

   public static async create(file: File, sizeMedia: ISizeMedia, SocialFeedId: string | null) {
      const token = localStorage.getItem(tokenKey);
      if (!token) {
         return new ModelError({
            httpCode: 401,
            message: "Unauthorization",
            errors: {
               process: [
                  {
                     code: "process.error.401",
                     message: "Đăng nhập để tiếp tục.",
                  },
               ],
            },
         });
      }
      const extension = file.name.split(".").pop();
      const response = await fetch(`${apiDomain}/www/social-feed-media`, {
         method: "POST",
         headers: {
            Authorization: `Bearer ${token}`,
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
         },
         body: JSON.stringify({
            "info.originalName": file.name,
            "info.size": file.size,
            "info.width": sizeMedia.width,
            "info.height": sizeMedia.height,
            "info.fileType": file.type,
            "info.extension": `.${extension}`,
            socialFeedId: SocialFeedId,
         }),
      });

      if (!response.ok) {
         return new ModelError((await response.json()) as TModelError);
      }

      const payload = (await response.json()) as TPayload<{
         newSocialFeedMedia: ISocialFeedMedia;
         presignedPost: IPresignedPost;
      }>;

      const presigned = await this.presignedPost(payload.data.presignedPost, file, payload.data.newSocialFeedMedia);
      if (!presigned) {
         return new ModelError((await response.json()) as TModelError);
      }
      return new SocialFeedMedia(payload.data.newSocialFeedMedia);
   }

   /**
    *
    * @param protocol
    * @param domain
    * @param position
    * @param SocialFeedId
    * @param keywordIds
    * @param id
    * @returns
    */

   public static async update({
      protocol,
      domain,
      position,
      SocialFeedId,
      keywordIds,
      id,
      description,
   }: {
      protocol?: "http" | "https";
      domain?: string;
      position?: number;
      SocialFeedId?: string;
      keywordIds?: string;
      id: string;
      description?: string;
   }) {
      const token = localStorage.getItem(tokenKey);

      if (!token) {
         return new ModelError({
            httpCode: 401,
            message: "Unauthorization",
            errors: {
               process: [
                  {
                     code: "process.error.401",
                     message: "Đăng nhập để tiếp tục.",
                  },
               ],
            },
         });
      }

      const response = await fetch(`${apiDomain}/www/social-feed-media`, {
         method: "PATCH",
         headers: {
            Authorization: `Bearer ${token}`,
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            id: encodeURI(id),
         },
         body: JSON.stringify({
            ...(!protocol ? undefined : { "info.protocol": protocol }), // Optional - "http", "https"
            ...(!domain ? undefined : { "info.domain": domain }), // Optional
            ...(!position ? undefined : { "info.position": position }), // Optional
            ...(!SocialFeedId ? undefined : { SocialFeedId: SocialFeedId }), // Optional
            ...(!keywordIds ? undefined : { keywordIds: keywordIds }),
            ...(!description ? undefined : { "info.description": description }),
         }),
      });

      if (!response.ok) {
         return new ModelError((await response.json()) as TModelError);
      }
      const payload = (await response.json()) as TPayload<{
         updatedSocialFeedMedia: ISocialFeedMedia;
      }>;

      return new SocialFeedMedia(payload.data.updatedSocialFeedMedia);
   }

   /**
    *
    * @param file
    * @param sizeMedia
    * @param SocialFeedId
    * @returns
    */
   public static async upload(file: File, SocialFeedId: string | null) {
      const token = localStorage.getItem(tokenKey);
      if (!token) {
         return new ModelError({
            httpCode: 401,
            message: "Unauthorization",
            errors: {
               process: [
                  {
                     code: "process.error.401",
                     message: "Đăng nhập để tiếp tục.",
                  },
               ],
            },
         });
      }

      const extension = file.name.split(".").pop();
      const formData = new FormData();
      formData.append("file", file);
      formData.append("socialFeedId", SocialFeedId ?? "");
      const response = await fetch(`${apiDomain}/www/social-feed-media/upload`, {
         method: "POST",
         headers: {
            Authorization: `Bearer ${token}`,
            "X-Requested-With": "XMLHttpRequest",
         },
         body: formData,
      });

      if (!response.ok) {
         return new ModelError((await response.json()) as TModelError);
      }

      const payload = (await response.json()) as TPayload<{
         newMedia: ISocialFeedMedia;
      }>;

      return new SocialFeedMedia(payload.data.newMedia);
   }

   public static async del(ids: string) {
      const token = localStorage.getItem(tokenKey);

      if (!token) {
         return new ModelError({
            httpCode: 401,
            message: "Unauthorization",
            errors: {
               process: [
                  {
                     code: "process.error.401",
                     message: "Đăng nhập để tiếp tục.",
                  },
               ],
            },
         });
      }

      const response = await Model.fetchData({
         endPoint: "social-feed-media",
         method: "DELETE",
         headers: {
            ["ids"]: ids,
         },
      });

      if (response instanceof ModelError) {
         return response;
      }
      return response;
   }
}
