/* eslint-plugin-disable react */
/*eslint-disable */
import Keyword from "./keyword.model";
import { Model, IModel, ModelError, TModelError, TPayload, TPaginate, Paginate } from "./model";
import Reaction, { EReactionTypes } from "./reaction.model";
import SocialFeedComment from "./socialFeedComment.model";
import SocialFeedMedia from "./socialFeedMedia.model";
import User from "./user.model";
import UserBookmark from "./userBookmark.model";
import UserMedia from "./userMedia.model";
import UserSubscribe from "./userSubscribe.model";
import { apiDomain, appDomain, tokenKey } from "./__variables";
import axios from "axios";

export enum ESocialFeedSortBy {
   id = "_id",
   title = "info.title",
   createdAt = "createdAt",
   updatedAt = "updatedAt",
   popular = "popular",
   recent = "recent",
}

export enum ESocialFeedStatus {
   draft = "draft",
   enable = "enable",
   disable = "disable",
   delete = "delete",
   reported = "reported",
}

export enum ESocialFeedType {
   socialFeed = "socialFeed",
   qna = "qna",
}

export enum ESocialFeedPostDeviceType {
   web = "web",
   app = "app",
}

export enum EBannerType {
   banner1 = "banner1",
   banner2 = "banner2",
   banner3 = "banner3",
}

export interface ISocialFeed extends IModel {
   ["info"]?: {
      ["title"]?: string;
      ["description"]?: string;
      ["descriptionWeb"]?: string;
      ["bookmarkCount"]?: number;
      ["viewCount"]?: number;
      ["fakeViewCount"]?: number;
   };
   ["options"]?: {
      ["status"]?: ESocialFeedStatus;
      ["coinRewardStatus"]?: ESocialFeedCoinRewardStatus;
      ["type"]?: ESocialFeedType;
   };
   ["keywordIds"]?: string[];
   ["groupIds"]?: string[];
   ["ownedBy"]: {
      ["doc"]: User;
      ["model"]: string;
   };
   ["hashtags"]?: string[];
   ["mediaCount"]: number;
   ["publishAt"]?: string;

   ["__keywords"]?: Keyword[];
   ["__bookmarks"]?: UserBookmark[];
   ["__bookmarkCount"]?: number;
   ["__replyCount"]?: number;
   ["__replies"]?: SocialFeedComment[];
   ["__reactionCount"]?: { [EReactionTypes.like]?: number };
   ["__medias"]?: SocialFeedMedia[];
   ["__thumbnailMedia"]?: SocialFeedMedia;
   ["__currentUserReactionsMap"]?: {
      [EReactionTypes.like]?: Reaction;
   };
   ["__currentUserBookmark"]?: UserBookmark;
   __subscribedByCurrentUser?: UserSubscribe;
   __subscribedCurrentUser?: UserSubscribe;
}
export enum ESocialFeedCoinRewardStatus {
   rewarded = "rewarded",
   refused = "refused",
}

class SocialFeed extends Model<ISocialFeed> implements ISocialFeed {
   public ["info"]?: {
      ["title"]?: string;
      ["description"]?: string;
      ["descriptionWeb"]?: string;
      ["bookmarkCount"]?: number;
      ["viewCount"]?: number;
      ["fakeViewCount"]?: number;
   };
   public ["options"]?: {
      ["status"]?: ESocialFeedStatus;
      ["type"]?: ESocialFeedType;
      ["coinRewardStatus"]?: ESocialFeedCoinRewardStatus;
   };
   public ["keywordIds"]?: string[];
   public ["groupIds"]?: string[];
   public ["ownedBy"]!: {
      ["doc"]: User;
      ["model"]: string;
   };
   public ["hashtags"]?: string[];
   public ["mediaCount"]!: number;
   public ["publishAt"]?: string;

   public ["__keywords"]?: Keyword[];
   public ["__bookmarks"]?: UserBookmark[];
   public ["__bookmarkCount"]?: number;
   public ["__replyCount"]?: number;
   public ["__replies"]?: SocialFeedComment[];
   public ["__reactionCount"]?: { [EReactionTypes.like]?: number };
   public ["__medias"]?: SocialFeedMedia[];
   public ["__thumbnailMedia"]?: SocialFeedMedia;
   public ["__currentUserReactionsMap"]?: {
      [EReactionTypes.like]?: Reaction;
   };
   public ["__currentUserBookmark"]?: UserBookmark;
   public "__subscribedByCurrentUser"?: UserSubscribe;
   public "__subscribedCurrentUser"?: UserSubscribe;

   constructor(data?: ISocialFeed) {
      super(data);

      Object.assign(this, data);
      if (this.__medias) {
         this.__medias = this.__medias.map((media) => new SocialFeedMedia(media));
      }
      if (this.__thumbnailMedia) {
         this.__thumbnailMedia = new SocialFeedMedia(this.__thumbnailMedia);
      }
      if (this.ownedBy?.doc?.__media) {
         this.ownedBy.doc.__media = new UserMedia(this.ownedBy.doc.__media);
      }
   }

   public static async create(
      {
         code,
         content,
         mediaIds,
         keywordIds,
         hashtags,
         publishAt,
      }: {
         content: string;
         mediaIds?: string[];
         keywordIds?: string[];
         hashtags?: string[];
         code?: ESocialFeedPostDeviceType;
         publishAt?: string;
      },
      draftId: string
   ) {
      const token = localStorage.getItem(tokenKey);

      if (!token) {
         return new ModelError({
            httpCode: 401,
            message: "Unauthorization",
            errors: {
               process: [
                  {
                     code: "process.error.401",
                     message: "Đăng nhập để tiếp tục.",
                  },
               ],
            },
         });
      }

      const response = await fetch(`${apiDomain}/www/social-feed`, {
         method: "POST",
         headers: {
            Authorization: `Bearer ${token}`,
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            "draft-id": draftId,
            code: ESocialFeedPostDeviceType.web,
         },
         body: JSON.stringify({
            "info.description": content,
            platform: "web",
            // ...!keywordIds ? undefined : { ["keywordIds"]: keywordIds },
            ...(!mediaIds ? undefined : { mediaIds: mediaIds }),
            ...(!hashtags ? undefined : { hashtags: hashtags }),
            ...(!publishAt ? undefined : { publishAt: publishAt }),
         }),
      });

      if (!response.ok) {
         return new ModelError((await response.json()) as TModelError);
      }

      const payload = (await response.json()) as TPayload<{
         newSocialFeed: ISocialFeed;
      }>;

      return payload.data.newSocialFeed;
   }

   public static async edit({
      id,
      code,
      content,
      mediaIds,
      keywordIds,
      hashtags,
   }: {
      id: string;
      code?: ESocialFeedPostDeviceType;
      content: string;
      mediaIds?: string[];
      keywordIds?: string[];
      hashtags?: string[];
   }) {
      const token = localStorage.getItem(tokenKey);

      if (!token) {
         return new ModelError({
            httpCode: 401,
            message: "Unauthorization",
            errors: {
               process: [
                  {
                     code: "process.error.401",
                     message: "Đăng nhập để tiếp tục.",
                  },
               ],
            },
         });
      }

      const response = await fetch(`${apiDomain}/www/social-feed`, {
         method: "PATCH",
         headers: {
            Authorization: `Bearer ${token}`,
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            id: id,
            code: ESocialFeedPostDeviceType.web,
         },
         body: JSON.stringify({
            "info.description": content,
            ...(!mediaIds ? undefined : { mediaIds: mediaIds }),
            ...(!hashtags ? undefined : { hashtags: hashtags }),
         }),
      });

      if (!response.ok) {
         return new ModelError((await response.json()) as TModelError);
      }

      const payload = (await response.json()) as TPayload<{
         updatedSocialFeed: ISocialFeed;
      }>;

      return new SocialFeed(payload.data.updatedSocialFeed);
   }

   public static async createDraft(type?: string) {
      const token = localStorage.getItem(tokenKey);

      if (!token) {
         return new ModelError({
            httpCode: 401,
            message: "Unauthorization",
            errors: {
               process: [
                  {
                     code: "process.error.401",
                     message: "Đăng nhập để tiếp tục.",
                  },
               ],
            },
         });
      }

      const response = await fetch(`${apiDomain}/www/social-feed/draft`, {
         method: "POST",
         headers: {
            Authorization: `Bearer ${token}`,
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            code: ESocialFeedPostDeviceType.web,
         },
         body: JSON.stringify({
            type: type ?? "socialFeed",
         }),
      });

      if (!response.ok) {
         return new ModelError((await response.json()) as TModelError);
      }

      const payload = (await response.json()) as TPayload<{
         newSocialFeed: ISocialFeed;
      }>;

      return payload.data.newSocialFeed;
   }

   public static async paginate({
      keywords,
      page,
      limit,
      sort,
      sortBy,
      ids,
      offset,
      followingOnly,
      exclusiveIds,
      type,
      cookiesToken,
      priorityIds,
      hashtag,
   }: {
      keywords?: string;
      ids?: string[];
      page?: number;
      limit?: number;
      sort?: "asc" | "desc";
      sortBy?: ESocialFeedSortBy;
      offset?: number;
      followingOnly?: 0 | 1; //0: all, 1: following only,
      exclusiveIds?: string;
      type?: ESocialFeedType;
      cookiesToken?: string;
      priorityIds?: string;
      hashtag?: string;
   }) {
      try {
         const token = typeof window !== "undefined" && localStorage.getItem(tokenKey);
         const lastToken = cookiesToken || token;
         const res = await axios({
            url: `${apiDomain}/www/social-feed/paginate`,
            method: "GET",
            headers: {
               ["Origin"]: "https://spacet.vn",
               "X-Requested-With": "XMLHttpRequest",
               ...(!page ? undefined : { page: String(page) }),
               limit: !limit ? String(20) : String(limit),
               offset: !offset ? String(0) : String(offset),
               sort: !sort ? "desc" : encodeURI(sort),
               "sort-by": !sortBy ? ESocialFeedSortBy.createdAt : encodeURI(sortBy),
               ...(!keywords ? undefined : { keywords: encodeURIComponent(keywords) }),
               ...(!ids ? undefined : { ids: String(ids) }),
               ...(!followingOnly ? undefined : { "following-only": String(followingOnly) }),
               ...(!exclusiveIds ? undefined : { "exclusive-ids": String(exclusiveIds) }),
               ...(!priorityIds ? undefined : { "priority-ids": String(priorityIds) }),
               ...(!type ? undefined : { type }),
               ...(!lastToken ? undefined : { Authorization: `Bearer ${lastToken}` }),
               ...(!hashtag ? undefined : { hashtag: hashtag }),
            },
         });

         if (res.status !== 200) {
            return new ModelError(res.data);
         }

         const payload = res.data as TPayload<{
            paginator: TPaginate<ISocialFeed>;
         }>;

         const paginate = new Paginate(payload?.data?.paginator, SocialFeed);

         return paginate;
      } catch (error) {}
   }

   public static async paginatePinned(cookiesToken?: string) {
      try {
         const token = typeof window !== "undefined" && localStorage.getItem(tokenKey);
         const lastToken = cookiesToken || token;
         const res = await fetch(`${apiDomain}/www/social-feed/pinned`, {
            method: "GET",
            cache: "no-store",
            headers: {
               ["Origin"]: "https://spacet.vn",
               "X-Requested-With": "XMLHttpRequest",
               limit: String(20),
               offset: String(0),
               ...(!lastToken ? undefined : { Authorization: `Bearer ${lastToken}` }),
            },
         });

         if (!res.ok) {
            return new ModelError((await res.json()) as TModelError);
         }

         const payload = (await res.json()) as TPayload<{
            pinned: ISocialFeed[];
         }>;

         const paginate = payload.data.pinned.map((item: ISocialFeed) => new SocialFeed(item));

         return paginate;
      } catch (error) {}
   }

   public static async getSocialFeedById(id: string, cookiesToken?: string) {
      try {
         const token = typeof window !== "undefined" && localStorage.getItem(tokenKey);
         const lastToken = cookiesToken || token;
         const res = await fetch(`${apiDomain}/www/social-feed`, {
            method: "GET",
            headers: {
               ["Origin"]: appDomain,
               "X-Requested-With": "XMLHttpRequest",
               ...(!lastToken ? undefined : { Authorization: `Bearer ${lastToken}` }),
               id: encodeURI(id),
            },
         });

         // Kiểm tra nếu res là ModelError
         if (res instanceof ModelError) {
            throw res; // Ném lỗi để SSR hoặc API Routes có thể xử lý đúng cách
         }

         // Kiểm tra lỗi HTTP (response không hợp lệ)
         if (!res.ok) {
            const errorPayload = await res.json().catch(() => null); // Tránh lỗi JSON không hợp lệ
            throw new ModelError(errorPayload ?? "Unexpected API error.");
         }

         // Đọc response dưới dạng text trước khi parse JSON
         const payloadText = await res.text();

         // Kiểm tra nếu response rỗng
         if (!payloadText) {
            throw new ModelError({
               httpCode: 500,
               message: "Unexpected API error.",
            });
         }

         let payload: { data: { socialFeed: unknown } };

         try {
            // Parse JSON an toàn
            payload = JSON.parse(payloadText);
         } catch (parseError) {
            throw new ModelError({
               httpCode: 500,
               message: "Unexpected API error.",
            });
         }

         // Kiểm tra nếu dữ liệu tồn tại
         if (!payload?.data?.socialFeed || typeof payload.data.socialFeed !== "object") {
            throw new ModelError({
               httpCode: 500,
               message: "Unexpected API error.",
            });
         }

         // Trả về dữ liệu đã được kiểm tra
         return {
            socialFeed: new SocialFeed(payload.data.socialFeed as ISocialFeed),
         };
      } catch (error) {}
   }

   public static async deleteSocialFeed({ id }: { id: string }) {
      const token = localStorage.getItem(tokenKey);
      if (!token) {
         return new ModelError({
            httpCode: 401,
            message: "401 Unauthorized error",
            errors: {
               process: [
                  {
                     code: "401",
                     message: "401 Unauthorized error",
                  },
               ],
            },
         });
      }
      try {
         const response = await fetch(`${apiDomain}/www/social-feed`, {
            method: "DELETE",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               Authorization: `Bearer ${token}`,
               id,
            },
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }
         return;
      } catch (error) {
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }
}

export default SocialFeed;
