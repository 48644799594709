import { Model } from "./model";

import User from "./user.model";
export enum EReactionSortBy {
   id = "_id",
   createdAt = "createdAt",
   updatedAt = "updatedAt",
}

export enum EReactionStatus {
   enable = "enable",
   disable = "disable",
}

export enum EReactionTargets {
   post = "post",
   comment = "comment",
}

export enum EReactionTypes {
   like = "like",
}
export interface IReaction extends Model {
   ["target"]: {
      ["doc"]: string;
      ["model"]: string;
      ["sourceDoc"]: string;
      ["sourceModel"]: string;
   };
   ["options"]?: {
      ["type"]?: EReactionTypes | null;
      ["status"]?: EReactionStatus | null;
   };
   ["userId"]?: string | null;
   ["__user"]?: User;
}

export class Reaction<T extends IReaction = IReaction> extends Model {
   public ["target"]!: {
      ["doc"]: string;
      ["model"]: string;
      ["sourceDoc"]: string;
      ["sourceModel"]: string;
   };
   public ["options"]?: {
      ["status"]?: EReactionStatus | null;
      ["type"]?: EReactionTypes | null;
   };
   public ["userId"]?: string | null;

   public ["__user"]?: User;

   constructor(data?: T) {
      super(data);
      Object.assign(this, data);
      if (this?.__user) {
         this.__user = new User(this.__user);
      }
   }
}

export default Reaction;
