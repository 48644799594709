import React, { FunctionComponent, HTMLAttributes } from "react";
import classnames from "classnames";
import styles from "./Container.module.scss";

interface LayoutContainerProps extends HTMLAttributes<HTMLDivElement> {
   children: React.ReactNode;
   className?: string;
}

const LayoutContainer: FunctionComponent<LayoutContainerProps> = ({ className, children, ...props }) => {
   return (
      <div className={classnames([styles.container, className])} {...props}>
         {children}
      </div>
   );
};

export default LayoutContainer;
