/**
 * Model(s)
 */
import { apiDomain, tokenKey } from "./__variables";
import Article from "./article.model";
import ArticleReaction from "./articleReaction.model";
import Comment, { ECommentSortBy, IComment } from "./comment.model";
import { ModelError, Paginate, TModelError, TPaginate, TPayload } from "./model";
import User from "./user.model";

export interface IArticleComment extends IComment {
   ["__reactionCount"]?: number;
   articleId: string | null;
   articleCommentId: string | null;

   __article?: Article | null;
   __articleComment?: ArticleComment | null;
   __reaction?: ArticleReaction | null;

   __replies?: ArticleComment[];
}

export class ArticleComment extends Comment<IArticleComment> implements IArticleComment {
   public ["__reactionCount"]?: number;
   public "articleId"!: string | null;
   public "articleCommentId"!: string | null;

   public "__article"!: Article | null;
   public "__articleComment"!: ArticleComment | null;
   public "__reaction"!: ArticleReaction | null;

   public "__replies"!: ArticleComment[];

   constructor(data?: IArticleComment) {
      super(data);

      Object.assign(this, data);

      if (this?.__reaction) {
         this.__reaction = new ArticleReaction(this.__reaction);
      }

      if (this?.__user) {
         this.__user = new User(this.__user);
      }

      if (this?.__replies) {
         this.__replies = this.__replies.map((reply) => new ArticleComment(reply));
      }

      if (this?.__replyToUsers) {
         this.__replyToUsers = this.__replyToUsers.map((replyToUser) => new User(replyToUser));
      }

      if (this?.__article) {
         this.__article = new Article(this.__article);
      }

      if (this?.__articleComment) {
         this.__articleComment = new ArticleComment(this.__articleComment);
      }
   }

   public static async paginate({
      id,
      commentId,
      page,
      limit,
      sort,
      sortBy,
   }: {
      id: string;
      commentId?: string;
      page?: number;
      limit?: number;
      sort?: "asc" | "desc";
      sortBy?: ECommentSortBy;
   }) {
      try {
         const token = localStorage.getItem(tokenKey);
         const res = await fetch(`${apiDomain}/www/Article/comment/paginate`, {
            method: "GET",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               id: id,
               ...(!token ? undefined : { Authorization: `Bearer ${token}` }),
               page: !page ? String(1) : String(page),
               limit: !limit ? String(20) : String(limit),
               sort: !sort ? "desc" : encodeURI(sort),
               "sort-by": !sortBy ? ECommentSortBy.createdAt : encodeURI(sortBy),
               ...(!commentId ? undefined : { "comment-id": commentId }),
            },
         });

         if (!res.ok) {
            return new ModelError((await res.json()) as TModelError);
         }

         const payload = (await res.json()) as TPayload<{
            paginator: TPaginate<IArticleComment>;
         }>;

         const paginate = new Paginate(payload?.data?.paginator, ArticleComment);

         return paginate;
      } catch (error: any) {}
   }
}

export default ArticleComment;
