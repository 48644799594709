/* eslint-disable max-len */
import { useEffect, useState } from "react";
import Link from "next/link";
import { FlashSale } from "@Models/flashSale.model";
import { IMenuItem } from "@Models/menuItem.model";
import Button from "@Components/button";
import { Paginate } from "@Models/model";
import Product, { IProduct } from "@Models/product.model";
import { IUserBookmark } from "@Models/userBookmark.model";
import ProductCategory from "./ProductCategory";
import BestSeller from "./BestSeller";
import FlashSaleSection from "./FlashSaleSection";
import styles from "./CuaHangNoiThat.module.scss";

interface IProps {
   isMobile: boolean;
   shopCategories: IMenuItem[];
   productsBanChay: {
      paginate: Paginate<Product, IProduct>;
      userBookmarks: IUserBookmark[];
   };
   productsPropose?: {
      paginate: Paginate<Product, IProduct>;
      userBookmarks: IUserBookmark[];
   };
   flashSaleWithProduct: {
      paginate: Paginate<Product, IProduct>;
      flashSales: FlashSale;
   };
}

const CuaHangNoiThat: React.FC<IProps> = ({
   productsBanChay,
   productsPropose,
   flashSaleWithProduct,
   isMobile,
   shopCategories,
}) => {
   const [state, setState] = useState<{ isActiveFlashSale?: boolean }>({});
   const [userBookmarks, setUserBookmarks] = useState([
      ...(productsBanChay?.userBookmarks ?? []),
      ...(productsPropose?.userBookmarks ?? []),
   ]);

   useEffect(() => {
      setUserBookmarks([...(productsBanChay?.userBookmarks ?? []), ...(productsPropose?.userBookmarks ?? [])]);
   }, [productsBanChay?.userBookmarks, productsPropose?.userBookmarks]);

   useEffect(() => {
      setState({
         isActiveFlashSale: isStartFlashSale(flashSaleWithProduct.flashSales),
      });
   }, [flashSaleWithProduct.flashSales]);

   const isStartFlashSale = (flashSales: FlashSale) => {
      if (!flashSales) return false;
      const currentTime = Date.now();
      const startTime = new Date(flashSales.info?.startDateTime || 0).getTime();
      const endTime = new Date(flashSales.info?.endDateTime || 0).getTime();
      return currentTime >= startTime && currentTime <= endTime;
   };

   const handleEndCountdownFlashSales = () => {
      setState({
         isActiveFlashSale: isStartFlashSale(flashSaleWithProduct.flashSales),
      });
   };

   const handleChangeUserBookmark = (action: "create" | "delete", userBookmark: IUserBookmark) => {
      setUserBookmarks((prevBookmarks) =>
         action === "create"
            ? [...prevBookmarks, userBookmark]
            : prevBookmarks.filter((bookmark) => bookmark._id !== userBookmark._id)
      );
   };

   return (
      <>
         {/* Launch of Nội thất giá sỉ */}
         <div className="block px-0 py-0 d-block d-md-none">
            <div className="w-100">
               <img
                  src={
                     "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/actual-works/2024-12-27/676e6e63fd2aeb5c6b0d30e5.webp"
                  }
                  alt=""
                  style={{ width: "100%", height: "125px", objectFit: "cover" }}
               />
            </div>
            <div className="p-12">
               <div className="fs-20 fw-600 lh-28 ">Mua hàng sỉ nhanh chóng tiện lợi chỉ trong 1 click</div>
               <div className="fs-14 fw-400 lh-22 mt-1">Liên hệ với chúng tôi để có thêm ưu đãi</div>
               <Link href="/noithatgiasi">
                  <Button
                     className="mt-2 rounded-3 d-flex align-items-center justify-content-center"
                     size="medium"
                     width="100%"
                  >
                     Xem chi tiết{" "}
                     <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                        <path
                           d="M6.5 4L10.5 8L6.5 12"
                           stroke="white"
                           strokeWidth="1.5"
                           strokeLinecap="round"
                           strokeLinejoin="round"
                        />
                     </svg>
                  </Button>
               </Link>
            </div>
         </div>
         {/* Finish of Nội thất giá sỉ */}

         {/* Launch of Cửa hàng nội thất */}
         <div className="block">
            <div className="block-sub ">
               <h2 className={styles["title"]}>Cửa hàng nội thất</h2>
            </div>
            <div className="sub-title">Thỏa sức lựa chọn sản phẩm nội thất bạn yêu thích</div>
            <ProductCategory categories={shopCategories} />
         </div>
         {/* Finish of Cửa hàng nội thất */}

         {/* Launch of Flash sale */}
         <div className="block px-0 px-md-4 pb-0 pb-md-4">
            {flashSaleWithProduct?.flashSales && (
               <FlashSaleSection
                  isMobile={isMobile}
                  paginateFlashSale={flashSaleWithProduct.paginate}
                  flashSale={flashSaleWithProduct.flashSales}
                  onEndCountdownFlashSales={handleEndCountdownFlashSales}
               />
            )}
            {!flashSaleWithProduct?.flashSales && state.isActiveFlashSale === undefined && (
               <div className="flash-sale-init"></div>
            )}
         </div>
         {/* Finish of Flash sale */}

         {/* Launch of Sản phẩm bán chạy */}
         <div className="block px-0 px-md-4  pb-md-4">
            <div className="block-sub px-12 px-md-0">
               <h2 className={styles["title"]}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                     <path
                        d="M16.0684 2.66669L14.2417 6.40002C12.9365 9.06715 11.1871 11.4928 9.0684 13.5734L8.8284 13.8C7.46769 15.121 6.69022 16.9303 6.6684 18.8267V19.0667C6.63236 22.7802 8.84521 26.1469 12.2684 27.5867L12.6151 27.7334C14.8602 28.687 17.3966 28.687 19.6417 27.7334H19.7217C23.1705 26.2349 25.3833 22.8133 25.3351 19.0534V13.2667C24.1858 15.8914 22.0986 17.9929 19.4817 19.16C19.4817 19.16 19.4817 19.16 19.4017 19.16C19.3217 19.16 18.3884 19.5467 17.9884 19.16C17.6311 18.7985 17.5969 18.2283 17.9084 17.8267L18.0017 17.76H18.0684C21.1294 15.4333 21.8431 11.1223 19.6951 7.93335C17.9617 5.29335 16.0684 2.66669 16.0684 2.66669Z"
                        fill="#FF6347"
                     />
                  </svg>
                  Sản phẩm bán chạy
               </h2>
               <Link title="" href="/shop/san-pham-ban-chay" className="view-all ">
                  <span className="viewall-text">Xem tất cả </span>
                  <span className="next-icon">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                           d="M9 6L15 12L9 18"
                           stroke="#FF6347"
                           strokeWidth="1.5"
                           strokeLinecap="round"
                           strokeLinejoin="round"
                        />
                     </svg>
                  </span>
               </Link>
            </div>
            <BestSeller
               className="best-seller"
               products={productsBanChay}
               userBookmarks={userBookmarks}
               handleChangeUserBookmark={handleChangeUserBookmark}
            />
         </div>
         {/* Finish of Sản phẩm bán chạy */}

         {/* Launch of Gợi ý cho bạn */}
         {false && (
            <div className="block px-0 px-md-4  pb-md-4">
               <div className="block-sub px-12 px-md-0">
                  <div className="title">Gợi ý cho bạn</div>{" "}
                  <Link title="" href="/shop" className="view-all ">
                     <span className="viewall-text">Xem tất cả </span>
                     <span className="next-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                           <path
                              d="M9 6L15 12L9 18"
                              stroke="#FF6347"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                           />
                        </svg>
                     </span>
                  </Link>
               </div>
               <BestSeller
                  className="suggestions"
                  products={productsPropose}
                  userBookmarks={userBookmarks}
                  handleChangeUserBookmark={handleChangeUserBookmark}
               />
            </div>
         )}
         {/* Finish of Gợi ý cho bạn */}
      </>
   );
};

export default CuaHangNoiThat;
