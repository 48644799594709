/* eslint-plugin-disable react */
/*eslint-disable */
/**
 * Model(s)
 */
import { Product } from "./product.model";

/**
 * Config(s)
 */
// import { apiDomain } from "./__variables";

/**
 * Core(s)
 */
import { Model, IModel, ModelError, TModelError, TPayload } from "./model";
import { apiDomain } from "./__variables";

export enum EType {
   partner = "partner",
   actualWork = "actualWork",
   design = "design",
   communityPost = "communityPost",
}

export enum ELocationSortBy {
   name = "info.name",
   createdAt = "createdAt",
   updatedAt = "updatedAt",
}

export enum ELocationStatus {
   enable = "enable",
   disable = "disable",
}

export enum ELocationType {
   country = "country",
   province = "province",
   district = "district",
   ward = "ward",
}

export interface ILocation extends IModel {
   ["id"]: string;
   ["info"]?: {
      ["name"]?: string | null;
      ["code"]?: string | null;
      ["slug"]?: string | null;
      ["areaPhone"]?: string | null;
   };
   ["parentId"]: string | null;
   ["options"]?: {
      ["status"]?: ELocationStatus;
      ["locationType"]?: ELocationType;
   };
}

export class Location extends Model<ILocation> implements ILocation {
   public ["id"]!: string;
   public ["info"]?: {
      ["name"]?: string | null;
      ["code"]?: string | null;
      ["slug"]?: string | null;
      ["areaPhone"]?: string | null;
   };
   public ["parentId"]!: string | null;
   public ["options"]?: {
      ["status"]?: ELocationStatus;
      ["locationType"]?: ELocationType;
   };

   constructor(data?: object) {
      super();

      Object.assign(this, data);
   }

   /**
    *
    * @returns
    */
   public static async getList({ isDistinct = 1, type = EType.partner }: { type?: EType; isDistinct?: 1 | 0 }) {
      try {
         const res = await Model.fetchData({
            method: "GET",
            endPoint: "location/list",
            headers: {
               ...(isDistinct ? { ["is-distinct"]: isDistinct.toString() } : undefined),
               ...(type ? { type } : undefined),
            },
         });
         if (res instanceof ModelError) {
            return res;
         }
         const payload = (await (res as any).json()) as TPayload<{
            locations: ILocation[];
         }>;
         return payload.data.locations.map((location) => new Location(location));
      } catch (error) {}
   }
}

export default Location;
