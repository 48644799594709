import ActualWork from "@Models/actualWork.model";
import ActualWorkComment from "@Models/actualWorkComment.model";
import Reaction, { IReaction } from "@Models/reaction.model";

export interface IActualWorkReaction extends IReaction {
   ["actualWorkId"]?: string | null;
   ["actualWorkCommentId"]?: string | null;

   ["__actualWork"]?: ActualWork | null;
   ["__actualWorkComment"]?: ActualWorkComment | null;
}

export class ActualWorkReaction extends Reaction<IActualWorkReaction> implements IActualWorkReaction {
   public ["actualWorkId"]?: string | null;
   public ["actualWorkCommentId"]?: string | null;

   public ["__actualWork"]?: ActualWork | null;
   public ["__actualWorkComment"]?: ActualWorkComment | null;

   constructor(data?: IActualWorkReaction) {
      super(data);
      if (this?.__actualWork) {
         this.__actualWork = new ActualWork(this.__actualWork);
      }

      if (this?.__actualWorkComment) {
         this.__actualWorkComment = new ActualWorkComment(this.__actualWorkComment);
      }
   }
}

export default ActualWorkReaction;
