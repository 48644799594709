import { IModel, Model } from "./model";

export enum EArticleCategoryMediaStatus {
   enable = "enable",
   disable = "disable",
}

export enum EArticleCategoryMediaStorageProvider {
   Amazon = "amazon",
   Microsoft = "microsoft",
   Others = "others",
}

export interface IArticleCategoryMedia extends IModel {
   ["info"]: {
      ["container"]: string | null;
      ["domain"]: string | null;
      ["originalName"]: string | null;
      ["protocol"]: string | null;
      ["size"]: number | null;
      ["extension"]: string | null;
      ["fileType"]: string | null;
   };
   ["options"]: {
      ["status"]: EArticleCategoryMediaStatus;
      ["storageProvider"]: EArticleCategoryMediaStorageProvider;
   };
   ["articleCategoryId"]: string | null;
   ["$url"]?: any | null;
}

export class ArticleCategoryMedia extends Model implements IArticleCategoryMedia {
   public ["info"]!: {
      ["container"]: string | null;
      ["domain"]: string | null;
      ["originalName"]: string | null;
      ["protocol"]: string | null;
      ["size"]: number | null;
      ["extension"]: string | null;
      ["fileType"]: string | null;
   };
   public ["options"]!: {
      ["status"]: EArticleCategoryMediaStatus;
      ["storageProvider"]: EArticleCategoryMediaStorageProvider;
   };

   public ["articleCategoryId"]!: string | null;

   public ["$url"]?: any | null;

   constructor(data?: IArticleCategoryMedia) {
      super(data);

      Object.assign(this, data);
      this.$url = `${this?.info?.protocol}://${this?.info?.domain}/${this?.info?.container}/${this._id}${this?.info?.extension}`;
   }
}

export default ArticleCategoryMedia;
